<script>
  import {
    Ripple,
    Button
  } from 'svelte-mui'
  import config, {
    configStore
  } from '../config'
  import FormularioHilo from './Hilos/FormularioHilo.svelte'
  import Notificaciones from './Notificaciones.svelte'
  import MenuPrincipal from './MenuPrincipal.svelte'
  import FormularioLogin from './FormularioLogin.svelte'
  import globalStore from '../globalStore'
  import MensajeRotativo from './MensajeRotativo.svelte'
  import Dialogos from './Dialogos/Dialogos.svelte'
  import DenunciasNav from './Moderacion/DenunciasNav.svelte'
  import Signal from '../signal'
  import SelectorDeComentarios from './Moderacion/SelectorDeComentarios.svelte';
  import Subir from './Subir.svelte'
  import {
    onMount
  } from 'svelte';
  import NavCategorias from './NavCategorias.svelte'
  import NadieLoSabra from './Especiales/NadieLoSabra.svelte'
  import Estadisticas from './Estadisticas.svelte';
  import {
    writable
  } from "svelte/store";
  import skinStore from "./Personalizacion/skinsStore"
  import skinsPorDefecto from './Personalizacion/skinsPorDefecto'
  import {
    localStore
  } from '.././localStore'
  
  import {
    Dialog
  } from 'svelte-mui'
  import Ajustes from './Dialogos/Ajustes.svelte';
  import Skins from './Personalizacion/Skins.svelte';
  let store2 = localStore("skins", {
    activo: 'Classic',
    skins: skinsPorDefecto
  })
  // import Lucesitas from './Lucesitas.svelte'
  // import Cuetitos from './Especiales/Cuetitos.svelte'
  // import CuentaRegresiva from './Especiales/CuentaRegresiva.svelte';
  const store = writable(localStorage.getItem("store") || "");
  export let notificaciones = window.notificaciones || []
  let mostrarMenu = false
  let mostrarFormularioHilo = false
  let protocoloMessi = false
  $: protocoloMessi = $configStore.general.modoMessi
  let protocoloSerenito = false
  $: protocoloSerenito = $configStore.general.modoSerenito
  let mostrarComputadorasConectadas = false
  let oculta
  let ocultarCategorias = false
  let compacta = false
  let prevScrollpos = window.pageYOffset;
  let visible = true
  let cantidadMensajes = 0
  store.subscribe(val => localStorage.setItem("store", val));
  if ($store.length == 0) {
    $store = "Mensajes Globales";
  }

  function onMensajeGlobal(mensaje) {
    if ($store.length >= 1000) {
      $store = mensaje + "................" + "Mensajes Globales";
    } else {
      $store = mensaje + "................" + $store;
    }
  }
  Signal.coneccion.on("MensajeGlobal", onMensajeGlobal)

  function onScroll(e) {
    compacta = !(window.pageYOffset == 0)
    ocultarCategorias = compacta
    let currentScrollPos = window.pageYOffset;
    oculta = currentScrollPos > prevScrollpos;
    prevScrollpos = currentScrollPos;
  }
  let height = 0
  // $: if(height) {
  //     try {
  //         document.querySelector("main").style.marginTop = (height - 17) + "px"
  //     } catch (error) {console.log(error)}
  // }
  let style = window.document.styleSheets[0];
  $: if (protocoloMessi) {
    style.insertRule("body {--color5:rgb(28 185 208)!important}", style.cssRules.length)
  } else if (protocoloSerenito) {
    style.insertRule("body {--color5:rgb(255 124 36)!important}", style.cssRules.length)
  }
  $: if (mostrarFormularioHilo && !$globalStore.usuario.estaAutenticado) {
    window.location = '/Inicio'
  }
  let scrollY
  let version = ``
  let modalHack = false
  const toObj = JSON.parse
  let activo = 'Classic'
  var skinsObject = toObj(localStorage.getItem('skins'))
  var Actualactivo = skinsObject.activo;
  activo = Actualactivo;
  var filtered = skinsObject.skins.filter(function(el) {
    return el != null;
  });
  const filterObj = filtered.filter((e) => e.nombre == Actualactivo);
  var styleData = filterObj[0].style;
  var styleNonSpace = styleData.replace(/ /g, '').toLowerCase();;
  if (styleData.includes("animation") ||
   styleData.includes("marquee") || 
   styleData.includes("glitch") || 
   styleData.includes("myMarquee")  ||
   styleData.includes("marquee2") ||
   styleData.includes("opacity") || 
   styleData.includes("transform") ||
   styleData.includes("clip-path") ||
   styleData.includes("visibility") ||
   styleNonSpace.includes("headersection") || 
   styleData.includes("display") ||
   styleData.includes("flip") ||
   styleData.includes("inner") ||
   styleData.includes("lightrope"))
   {
    modalHack = true;
    restaurarSkins();
  }
  window.addEventListener('load', function() {
    var element = document.getElementById("myMarquee");
    if (element != null && element != undefined) {
      element.style.setProperty("display", "flex", "important");
    }
  })

  function restaurarSkins() {
    store2.update(skins => {
      skins = {
        activo: activo,
        skins: skinsPorDefecto
      }
      console.log(skins)
      document.getElementById("skin").innerHTML = skins.skins.filter(s => s.nombre == skins.activo)[0].style
      return skins;
    })
  }
  $: mob = isMobile();

  function isMobile() {
    let check = false;
    (function(a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
</script>
<svelte:window on:scroll={onScroll} bind:scrollY={scrollY} />
<NadieLoSabra />
<header class:oculta class:protocoloMessi class:protocoloSerenito bind:offsetHeight={height}>
  <nav>
    <!-- <Lucesitas/> -->
    <div class="nav-principal" class:modoSticky={scrollY> 200}> <div class:visible class:oculta class="fondo nav-categorias" style="position:absolute;left:0;top:0;width:100%">
        <!-- <Cuetitos/> -->
        <!-- <CuentaRegresiva/> --> {#if  scrollY < 50 && $globalStore.usuario.estaAutenticado}   
                
				<Estadisticas/>
              {/if}
           
          
			</div>
     
			<span on:click={() => mostrarMenu = !mostrarMenu} style="padding: 0 8px;">
				<icon class="fe fe-menu"/>
				<Ripple/>
			</span>          
          {#if mob}             
        
			<a href="/" class="svelte-1dl9wbv" style="font-family: terminator; position: relative;font-size: 70%;">
				<div class="ripple svelte-po4fcb" data-color="currentColor"></div>
       
        
				<div id="myLogo" class="hero-container">
					<h1 style="text-align:left;float:left;padding-top: 3%;" class="hero glitch layers" data-text="UFFTOPIA">
						<span>UFFTOPIA</span>
					</h1> 
				</div>
			</a>
          {:else}          
			<a href="/" class="svelte-1dl9wbv" style="font-family: terminator; position: relative;">
				<div class="ripple svelte-po4fcb" data-color="currentColor"></div>
				<div id="myLogo" class="hero-container">
					<h1  style="text-align:left;float:left;padding-top: 3%;" class="hero glitch layers" data-text="UFFTOPIA">
						<span>UFFTOPIA</span>
					</h1>			
				</div>
			</a>
        
          {/if}
          

          
			<!-- <CuentaRegresiva/> -->
			<!-- <MensajeRotativo/> -->
			<div class="estadisticas">
				<!-- {#if mostrarComputadorasConectadas}
                  <span transition:fade={{duration: 5000 }}   on:introend="{() => mostrarComputadorasConectadas = false}">
                      {computadorasConectadas} computadora{computadorasConectadas!=1?'s':''} conectada{computadorasConectadas!=1?'s':''}
                  </span>
              {/if} -->
			</div>
          {#if protocoloMessi}
              
			<div class="messi"></div>
          {:else if protocoloSerenito}
              
			<!-- <div class="serenito"></div> -->
          {/if}

          
			<div class="nav-botones" style="position: relative;">
              
              {#if $globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar}
                  
				<a href="/Moderacion">
					<!-- <span style="height: 48px;display: flex;align-items: center;"> -->
					<!-- <icon class="fe fe-triangle"/>
                          -->
					<!-- <Ripple/> -->
					<!-- </span> -->
					<Button icon dense>
						<span style="top: -1px;
                          font-size: 24px;
                          padding: 0 4px;">#</span>
					</Button>
				</a>
              {/if}
              {#if $globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar}
                  
				<DenunciasNav/>
              {/if}

              
				<a href="/Buscar"class="nav-boton"  style="height:100%">
					<!-- <Ripple/> -->
					<Button icon dense>
						<span class="fe fe-search"></span>
					</Button>
				</a>
              {#if $globalStore.usuario.estaAutenticado}
                  
				<Notificaciones bind:notificaciones/>
              {:else}
                  
				<a href="/Login"class="nav-boton"  style="height:100%">
					<!-- <Ripple/> -->
					<Button icon dense>
						<span class="fe fe-user"></span>
					</Button>
				</a>
              {/if}

          
			</div>
          {#if !protocoloMessi || $globalStore.usuario.esMod}
          
			<span class="nav-boton custom-btn btn-13 crear-hilo-boton" on:click={() => mostrarFormularioHilo = true}>
				<span style="width:max-content; margin-right: 6px;cursor: pointer;">topic</span>
				<span class="fe fe-plus-square"></span>
				<Ripple/>
			</span>
          {/if}

          
			<FormularioHilo bind:mostrar ={mostrarFormularioHilo}/>
			<FormularioLogin/>
		</nav>
		<NavCategorias visible={!ocultarCategorias}></NavCategorias>
     {#if $globalStore.usuario.estaAutenticado} 
        
		<section id="myMarquee" class="marquee">
			<div class="marquee2">  
            {$store}        
          </div>
		</section>  
    {/if}

	</header>
	<MenuPrincipal bind:mostrar={mostrarMenu}/>
	<Dialog  visible={modalHack} modal={true}>
		<div slot="title">Error al aplicar Skin</div>
		<ul>
			<div>Anon, el código que agregaste en Ajustes...Skins, posee un hack que limita una o más funcionalidades de la página.</div>
			<br>
				<br>
					<div>Retirá la parte del código que contiene el hack y volvé a intentar.</div>
					<div class="actions center">
						<Button color="primary" on:click={() => modalHack = false}>Ok</Button>
					</div>
				</ul>
			</Dialog>
			<Dialogos></Dialogos>

{#if $globalStore.usuario.estaAutenticado && $globalStore.usuario.esMod}
  
			<SelectorDeComentarios/>
{/if}

			<Subir></Subir>
			<!-- <Personalizacion></Personalizacion> -->
			<style>
  /*NAVBAR*/
.protocoloMessi  .nav-principal{
  background: rgb(0, 133, 241) !important;
}
.protocoloSerenito  .nav-principal{
  background-image: url(/imagenes/serenito.gif) !important;
  background-size: 98px !important;
  background-position-y: center !important;
}
.protocoloSerenito :global(.noti-cont) {
  background: #5f05fc !important;
}

.nav-principal {
  border-top: solid var(--color5) 2px;
  align-items: stretch !important;
  height: 48px;
  /* margin-bottom: 10px; */
  background: linear-gradient(90deg, rgb(11 16 22), var(--color2), rgb(39 28 43)) !important;
}

.nav-principal .fondo {
  height: 48px;
}
.nav-principal>*, .nav-principal nav-botones  span{
  /* height: 48px; */
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.nav-principal, .nav-principal a, :global(.nav-boton) {
  display: flex;
  align-items: center;
}
/*.nav-principal > {
  flex: 1;
}*/

/* :global(.nav-boton), .nav-principal a, .nav-principal icon {
  padding: 0 8px;
} */

.nav-botones {
margin-left: auto;
align-self: center;
font-size: 16px;
display: flex;
margin-right: 16px;
}
.nav-botones :global(button) {
  overflow: visible;
}
.crear-hilo-boton{
  height: 100%;
  background: var(--color5);
  font-family: 'terminator';
  font-size: 18px;
  padding: 8px;
  color: var(--color-texto1); 
}

.btn-13 {
color: #0bf4f3;
box-shadow: 0 0 5px var(--color5), 0 0 5px var(--color5) inset;
border: 1.5px solid var(--color5);
z-index: 1;
}
.btn-13:after {
position: absolute;
content: "";
width: 100%;
height: 0;
bottom: 0;
left: 0;
z-index: -1;
box-shadow: 0 0 5px var(--color5), 0 0 5px var(--color5) inset;
transition: all 0.3s ease;
}
.btn-13:hover {

}
.btn-13:hover:after {
top: 0;
height: 100%;
}
.btn-13:active {
top: 2px;
}
.custom-btn {
color: #fff; 
background: transparent;
cursor: pointer;
transition: all 0.3s ease;
position: relative;  
}

:global(.nav-boton) {
color: var(--color-texto1);
display: inline-flex;
position: relative;
}

header {
  /* margin-bottom: 10px; */
  /* position: fixed; */
  z-index: 10;
  top: 0;
  width: 100vw;
  transition: linear 0.2s;
  position: relative;
}
.compacta  .nav-categorias {
  margin: 0;
  padding: 0;
  gap: 4px;
}
/* .compacta  .nav-categorias a{
  font-size: 14px !important;
} */

/* .nav-categorias {
  opacity: 1;
  transition: linear 0.1s;

} */

/* :global(main) {
  margin-top: 120px;
} */

/* .oculta {
  transform: translateY(-120px) ;
} */

/* .ocultarCategorias {
  opacity: 0;
} */

@media(max-width:600px)
{
  .nav-categorias {
      display: none;
  }
  header {
      margin-bottom: 10px;
      position: fixed;
      z-index: 10;
      top: 0;
      width: 100vw;
      transition: linear 0.2s;
  }
  :global(main){
      margin-top: 50px;
  }

  .oculta {
      transform: translateY(-50px) ;
  }
}

.version {
  font-size: 10px;
  position: absolute;
  bottom: 1px;
  left:4px;
  font-family: ubuntu;
  width: max-content;
}

:global(.noti-cont) {
  position: absolute;
  left: 12px;
  width: auto;
  top: 2px;
  font-family: ubuntu;
}
.nav-principal :global(.noti-cont span)  {
  position: relative;
}

.estadisticas {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  color: #334d67;
  justify-self: center;
  margin: auto;
  overflow: hidden;
}
.messi {
  background-image: url("/imagenes/messi.gif");
  height: 46px;
  max-height: 100%;
  background-size: 70px;
  position: absolute;
  mix-blend-mode: overlay;
  width: 100%;
  pointer-events: none; 
}
/* Gorritos */
/* .rozed::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 7px;
  height: 32px;
  width: 32px;
  transform: rotate(-4deg) scaleX(-1);
  background: url(/imagenes/colores/gorrito.png) 0% 0% / 85% no-repeat;
  background-repeat: no-repeat;
} */

.modoSticky {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 36px;
  z-index: 1;
}

.modoSticky .rozed::after {
  top: 0px;
}
.modoSticky .version {
  display: none;
}
.modoSticky .fondo {
  height: 36px;
}
.luto {
  background: url(/imagenes/luto.png);
  width: 16px;
  height: 50px;
  background-size: 100%;
  position: absolute;
  top: 11px;
  background-repeat: no-repeat;
  left: 78px;
}
/* .estadisticas span {
  animation: baja 5s infinite linear;
}

@keyframes baja {
  0% {
      transform: translateY(-40px);
  }
  100% {
      transform: translateY(40px);
  }
} */

/**************************/
.hero {  
line-height: 1; 
color: #fff; 
/* Bright things in dark environments usually cast that light, giving off a glow */
filter: drop-shadow(0 1px 3px);
}
.layers {
position: relative;
}

.layers::before,
.layers::after {
content: attr(data-text);
position: absolute;
width: 110%;
z-index: -1;
}

.layers::before {
top: 10px;
left: 15px;
color: #e0287d;
}

.layers::after {
top: 5px;
left: -10px;
color: #1bc7fb;
}


@keyframes paths {
0% {
  clip-path: polygon(
    0% 43%,
    83% 43%,
    83% 22%,
    23% 22%,
    23% 24%,
    91% 24%,
    91% 26%,
    18% 26%,
    18% 83%,
    29% 83%,
    29% 17%,
    41% 17%,
    41% 39%,
    18% 39%,
    18% 82%,
    54% 82%,
    54% 88%,
    19% 88%,
    19% 4%,
    39% 4%,
    39% 14%,
    76% 14%,
    76% 52%,
    23% 52%,
    23% 35%,
    19% 35%,
    19% 8%,
    36% 8%,
    36% 31%,
    73% 31%,
    73% 16%,
    1% 16%,
    1% 56%,
    50% 56%,
    50% 8%
  );
}

5% {
  clip-path: polygon(
    0% 29%,
    44% 29%,
    44% 83%,
    94% 83%,
    94% 56%,
    11% 56%,
    11% 64%,
    94% 64%,
    94% 70%,
    88% 70%,
    88% 32%,
    18% 32%,
    18% 96%,
    10% 96%,
    10% 62%,
    9% 62%,
    9% 84%,
    68% 84%,
    68% 50%,
    52% 50%,
    52% 55%,
    35% 55%,
    35% 87%,
    25% 87%,
    25% 39%,
    15% 39%,
    15% 88%,
    52% 88%
  );
}

30% {
  clip-path: polygon(
    0% 53%,
    93% 53%,
    93% 62%,
    68% 62%,
    68% 37%,
    97% 37%,
    97% 89%,
    13% 89%,
    13% 45%,
    51% 45%,
    51% 88%,
    17% 88%,
    17% 54%,
    81% 54%,
    81% 75%,
    79% 75%,
    79% 76%,
    38% 76%,
    38% 28%,
    61% 28%,
    61% 12%,
    55% 12%,
    55% 62%,
    68% 62%,
    68% 51%,
    0% 51%,
    0% 92%,
    63% 92%,
    63% 4%,
    65% 4%
  );
}

45% {
  clip-path: polygon(
    0% 33%,
    2% 33%,
    2% 69%,
    58% 69%,
    58% 94%,
    55% 94%,
    55% 25%,
    33% 25%,
    33% 85%,
    16% 85%,
    16% 19%,
    5% 19%,
    5% 20%,
    79% 20%,
    79% 96%,
    93% 96%,
    93% 50%,
    5% 50%,
    5% 74%,
    55% 74%,
    55% 57%,
    96% 57%,
    96% 59%,
    87% 59%,
    87% 65%,
    82% 65%,
    82% 39%,
    63% 39%,
    63% 92%,
    4% 92%,
    4% 36%,
    24% 36%,
    24% 70%,
    1% 70%,
    1% 43%,
    15% 43%,
    15% 28%,
    23% 28%,
    23% 71%,
    90% 71%,
    90% 86%,
    97% 86%,
    97% 1%,
    60% 1%,
    60% 67%,
    71% 67%,
    71% 91%,
    17% 91%,
    17% 14%,
    39% 14%,
    39% 30%,
    58% 30%,
    58% 11%,
    52% 11%,
    52% 83%,
    68% 83%
  );
}

76% {
  clip-path: polygon(
    0% 26%,
    15% 26%,
    15% 73%,
    72% 73%,
    72% 70%,
    77% 70%,
    77% 75%,
    8% 75%,
    8% 42%,
    4% 42%,
    4% 61%,
    17% 61%,
    17% 12%,
    26% 12%,
    26% 63%,
    73% 63%,
    73% 43%,
    90% 43%,
    90% 67%,
    50% 67%,
    50% 41%,
    42% 41%,
    42% 46%,
    50% 46%,
    50% 84%,
    96% 84%,
    96% 78%,
    49% 78%,
    49% 25%,
    63% 25%,
    63% 14%
  );
}

90% {
  clip-path: polygon(
    0% 41%,
    13% 41%,
    13% 6%,
    87% 6%,
    87% 93%,
    10% 93%,
    10% 13%,
    89% 13%,
    89% 6%,
    3% 6%,
    3% 8%,
    16% 8%,
    16% 79%,
    0% 79%,
    0% 99%,
    92% 99%,
    92% 90%,
    5% 90%,
    5% 60%,
    0% 60%,
    0% 48%,
    89% 48%,
    89% 13%,
    80% 13%,
    80% 43%,
    95% 43%,
    95% 19%,
    80% 19%,
    80% 85%,
    38% 85%,
    38% 62%
  );
}

1%,
7%,
33%,
47%,
78%,
93% {
  clip-path: none;
}
}

.movement {
/* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
position: relative;
animation: movement 8s step-end infinite;
}

@keyframes movement {
0% {
  top: 0px;
  left: -20px;
}

15% {
  top: 10px;
  left: 10px;
}

60% {
  top: 5px;
  left: -10px;
}

75% {
  top: -5px;
  left: 20px;
}

100% {
  top: 10px;
  left: 5px;
}
}

@keyframes opacity {
0% {
  opacity: 0.1;
}

5% {
  opacity: 0.7;
}

30% {
  opacity: 0.4;
}

45% {
  opacity: 0.6;
}

76% {
  opacity: 0.4;
}

90% {
  opacity: 0.8;
}

1%,
7%,
33%,
47%,
78%,
93% {
  opacity: 0;
}
}

.font {
animation: font 7s step-end infinite;
}

@keyframes font {
0% {
  font-weight: 100;
  color: #e0287d;
  filter: blur(3px);
}

20% {
  font-weight: 500;
  color: #fff;
  filter: blur(0);
}

50% {
  font-weight: 300;
  color: #1bc7fb;
  filter: blur(2px);
}

60% {
  font-weight: 700;
  color: #fff;
  filter: blur(0);
}

90% {
  font-weight: 500;
  color: #e0287d;
  filter: blur(6px);
}
}

.glitch span {
animation: paths 5s step-end infinite;
}

.glitch::before {
animation: paths 5s step-end infinite, opacity 5s step-end infinite,
  font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
animation: paths 5s step-end infinite, opacity 5s step-end infinite,
  font 7s step-end infinite, movement 8s step-end infinite;
}

.hero-container {
position: relative;  
text-align: center;
}

.nav-categorias {
     
  flex-wrap: wrap;       
  justify-content: center;
  transition: 0.2s;
}


.marquee {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  background:var(--color5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  background-image:     
    repeating-linear-gradient(90deg, #23277600, #23277600 2px, #040a2444 2px, #040a2444 3px);
}

.marquee .marquee2 {
  display: inline-block;
  padding-left: 4.4rem;
  text-indent: 0;
  animation: marquee 45s linear infinite;
  font-weight: bold;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #f2f2f2;
   
}
@keyframes marquee {
  0% {
    transform: translate(20%, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}



svg {
	width: 50px;
}

.pumkin {
	fill: #282843;
}

.lanterna {
	fill: #f2620f;
	filter: url(#light);
}

.lanterna-safari {
	fill: #f2620f;
}

.iris {
	fill: #111119;
}
.eye {
	animation: eye 4s ease-in-out infinite;
	transform-origin: 50% 50%;
}

.shadow {
	opacity: 0.8;
	filter: blur(5px);
	fill: #000;
	animation: moveshadow 4s ease-in-out infinite;
	transform-origin: 50% 50%;
}

.move {
	animation: movepumkin 4s ease-in-out infinite;
}

@keyframes movepumkin {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes moveshadow {
	0% {
		transform: scale(1);
		opacity: 0.9;
	}
	50% {
		transform: scaleX(0.8);
		opacity: 0.2;
	}
	100% {
		transform: scale(1);
		opacity: 0.9;
	}
}

@keyframes eye {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

main-loader {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}

.main-fader { 
  position: absolute;
 
}
.main-fader .loader {

  transform: translate(-50%, -50%);
}
.main-fader .loader svg {
 
}
.main-fader .loader svg path {
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  color: #26a380;
}
.main-fader .loader svg path.path-7 {
  animation-delay: -1s;
}
.main-fader .loader svg path.path-6 {
  animation-delay: -0.875s;
}
.main-fader .loader svg path.path-5 {
  animation-delay: -0.75s;
}
.main-fader .loader svg path.path-4 {
  animation-delay: -0.625s;
}
.main-fader .loader svg path.path-3 {
  animation-delay: -0.5s;
}
.main-fader .loader svg path.path-2 {
  animation-delay: -0.375s;
}
.main-fader .loader svg path.path-1 {
  animation-delay: -0.25s;
}
.main-fader .loader svg path.path-0 {
  animation-delay: -0.125s;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}


.lightrope {
	 text-align: center;
	 white-space: nowrap;
	 overflow: hidden;
	 position: absolute;
	 z-index: 1;
	 margin: -15px 0 0 0;
	 padding: 0;
	 pointer-events: none;
	 
}
 .lightrope li {
	 position: relative;
	 animation-fill-mode: both;
	 animation-iteration-count: infinite;
	 list-style: none;
	 margin: 0;
	 padding: 0;
	 display: block;
	 width: 12px;
	 height: 28px;
	 border-radius: 50%;
	 margin: 20px;
	 display: inline-block;
	 background: rgba(0, 247, 165, 1);
	 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
	 animation-name: flash-1;
	 animation-duration: 2s;
}
 .lightrope li:nth-child(2n+1) {
	 background: rgba(0, 255, 255, 1);
	 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
	 animation-name: flash-2;
	 animation-duration: 0.4s;
}
 .lightrope li:nth-child(4n+2) {
	 background: rgba(247, 0, 148, 1);
	 box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
	 animation-name: flash-3;
	 animation-duration: 1.1s;
}
 .lightrope li:nth-child(odd) {
	 animation-duration: 1.8s;
}
 .lightrope li:nth-child(3n+1) {
	 animation-duration: 1.4s;
}
 .lightrope li:before {
	 content: "";
	 position: absolute;
	 background: #222;
	 width: 10px;
	 height: 9.3333333333px;
	 border-radius: 3px;
	 top: -4.6666666667px;
	 left: 1px;
}
 .lightrope li:after {
	 content: "";
	 top: -14px;
	 left: 9px;
	 position: absolute;
	 width: 52px;
	 height: 18.6666666667px;
	 border-bottom: solid #222 2px;
	 border-radius: 50%;
}
 .lightrope li:last-child:after {
	 content: none;
}
 .lightrope li:first-child {
	 margin-left: -40px;
}
 @keyframes flash-1 {
	 0%, 100% {
		 background: rgba(0, 247, 165, 1);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
	}
	 50% {
		 background: rgba(0, 247, 165, 0.4);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
	}
}
 @keyframes flash-2 {
	 0%, 100% {
		 background: rgba(0, 255, 255, 1);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 1);
	}
	 50% {
		 background: rgba(0, 255, 255, 0.4);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
	}
}
 @keyframes flash-3 {
	 0%, 100% {
		 background: rgba(247, 0, 148, 1);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
	}
	 50% {
		 background: rgba(247, 0, 148, 0.4);
		 box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
	}
}
 

HTML CSSResult Skip Results Iframe
EDIT ON
.cartoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vmin;
  height: 80vmin;
}

.cartoon div {
  position: absolute;
  box-sizing: border-box;
}

.b {
  border: 0.5vmin solid;
}

.r {
  border-radius: 100%;
}

.hb::before,
.ha::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}

/****/
/* background-size: 45vmin 35vmin, 50vmin 70vmin, 60vmin 50vmin, 60vmin 50vmin; */
@keyframes snow {
  0% {
    background-position: 0 0, 0 0, 0 0, 0 0;
  }
  40% {
    background-position: 10px 14vmin, -20px 28vmin, 20px 20vmin, 75px 20vmin;
  }
  60% {
    background-position: -10px 21vmin, -30px 42vmin, 30px 30vmin, 50px 30vmin;
  }
  100% {
    background-position: 0 35vmin, 0 70vmin, 0 50vmin, 0 50vmin;
  }
}

html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000;
  background-image:
    radial-gradient(circle at 50% 50%, white 2.5%, transparent 0),
    radial-gradient(circle at 30% 90%, white 1.5%, transparent 0),
    radial-gradient(circle at 70% 10%, white 1%, transparent 0),
    radial-gradient(circle at 10% 40%, white 1%, transparent 0);
  background-size: 45vmin 35vmin, 50vmin 70vmin, 60vmin 50vmin, 65vmin 50vmin;
  background-position: 0 0, 0 0, 0 0, 0 0;
/*   animation: snow 4s infinite linear; */
}

.cartoon {
  --skin: #fca;
  --beard: #eee;
  --eyes: #630a;
  --cheeks: #f001;
  --belt: #111;
  --belt-buckle: gold;
  --suit: #a00;
}

.cartoon::before {
  top: 90%;
  left: 50%;
  width: 200vmax;
  height: 200vmax;
  background: white;
  transform: translate(-50%, 0) rotate(1deg);
}

.head {
  width: 25%;
  height: 25%;
  background: var(--skin);
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.beard {
  width: 30%;
  height: 40%;
  background: var(--beard);
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 100% / 120% 120% 80% 80%;
}

.mustache {
  width: 10%;
  height: 10%;
  background: #fff;
  border-radius: 100% 20% 100% 0;
  top: 31%;
  left: 51%;
  transform-origin: top right;
  transform: translate(-100%, 0) rotate(25deg);
}

.mustache + .mustache {
  left: 49%;
  border-radius: 20% 100% 0 100%;
  transform-origin: top left;
  transform: rotate(-25deg);
}

.eyes {
  width: 2%;
  height: 2%;
  background: var(--eyes);
  top: 23%;
  left: 45%;
  box-shadow: 6.66vmin 0 var(--eyes);
}

.cheeks {
  width: 5%;
  height: 3%;
  background: var(--cheeks);
  top: 25.5%;
  left: 43%;
  box-shadow: 7.25vmin 0 var(--cheeks);
}

.body {
  width: 50%;
  height: 50%;
  background: var(--suit);
  border-radius: 100% / 150% 150% 25% 25%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 0);
  background-image:
    radial-gradient(circle at 50% -50%, transparent 75%, var(--belt) 0 83%, transparent 0),
    linear-gradient(to right, transparent 42%, white 43% 57%, transparent 58%)
}

.arm {
  width: 65%;
  height: 40%;
  background: #a00;
  border-radius: 100% / 170% 170% 25% 25%;
  top: 37%;
  left: 50%;
  transform: translate(-50%, 0);
  abox-shadow: inset 0 0 0 10vmin #0002;
  background-image: linear-gradient(transparent 20%, #0003)
}

.belt {
  width: 20%;
  height: 15%;
  border: 1vmin solid var(--belt-buckle);
  border-radius: 1vmin;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--belt-buckle);
  box-shadow: inset 1vmin 0 0 1.75vmin var(--belt);
}

.buttons {
  width: 5%;
  height: 5%;
  background: var(--belt);
  color: var(--belt);
  top: 33%;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 
    0 5vmin, 0 10vmin 0 0.1vmin, 0 22vmin;
  opacity: 0.75;
}

.hat {
  width: 23%;
  height: 20%;
  background: var(--suit);
  border-radius: 100% 20% 0 0;
  top: -2%;
  left: 50%;
  transform: translate(-50%, 0) rotate(1deg);
}

.hat::before {
  width: 110%;
  height: 40%;
  border-radius: 100% / 50%;
  bottom: -17%;
  left: -5%;
  box-shadow: inset 0 4vmin white;
  transform: rotate(-2deg);
}

.hat::after {
  width: 8vmin;
  height: 8vmin;
  border-radius: 50%;
  background: var(--beard);
  right: -5vmin;
  top: -15%;
}

.hands {
  width: 13%;
  height: 13%;
  background: var(--belt);
  top: 70%;
  left: 18%;
  box-shadow: 41vmin 0 var(--belt);
}

.leg {
  width: 19%;
  height: 25%;
  background: var(--suit);
  transform: skew(2deg);
  top: 75%;
  left: 29%;
  background-image: 
    linear-gradient(#0002, transparent 70%, var(--belt) 0);
}

.leg + .leg {
  left : 52%;
}

.leg::after {
  width: 110%;
  height: 20%;
  background: black;
  bottom: 0;
  left: -6%;
  border-radius: 10vmin 10vmin 0 0;
}

.leg + .leg::after {
  left: -4%;
}



/***/

</style>